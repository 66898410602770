import {createStore} from "vuex";
import Alipay_Logo from "@/assets/logo/alipay-logo.png"
import WeChat_Logo from "@/assets/logo/wechat-logo.svg"
import YunShanFu_Logo from "@/assets/logo/yunshanfu-logo.png"

import OSLogo_Windows from "@/assets/os-icon/Windows.svg";
import OSLogo_CentOS from "@/assets/os-icon/CentOS.svg";
import OSLogo_Redhat from "@/assets/os-icon/RedHat.png";
import OSLogo_Ubuntu from "@/assets/os-icon/Ubuntu.svg";
import OSLogo_Deepin from "@/assets/os-icon/Deepin.png";
import OSLogo_Debain from "@/assets/os-icon/Debian.svg";
import OSLogo_Fedora from "@/assets/os-icon/Fedora.webp";
import OSLogo_UOS from "@/assets/os-icon/UOS.png";
import OSLogo_Default from "@/assets/os-icon/CD.svg";

import InstanceLogo_Universal from "@/assets/instance-icon/cmd.webp"
import InstanceLogo_Steam from "@/assets/instance-icon/steam-normal.png"
import InstanceLogo_Terraria from "@/assets/instance-icon/terraria.png"
import InstanceLogo_Minecraft from "@/assets/instance-icon/minecraft.png"
import InstanceLogo_MinecraftJava from "@/assets/instance-icon/java.webp"
import InstanceLogo_MinecraftJavaSpigot from "@/assets/instance-icon/spigot.png"
import InstanceLogo_MinecraftJavaPaper from "@/assets/instance-icon/paper.svg"
import InstanceLogo_MinecraftJavaSponge from "@/assets/instance-icon/sponge.jpg"
import InstanceLogo_MinecraftJavaForge from "@/assets/instance-icon/forge.svg"
import InstanceLogo_MinecraftJavaFabric from "@/assets/instance-icon/fabric.png"
import InstanceLogo_MinecraftJavaBukkit from "@/assets/instance-icon/bukkit.png"
import InstanceLogo_MinecraftJavaGeyser from "@/assets/instance-icon/geyser.png"
import InstanceLogo_MinecraftBedrock from "@/assets/instance-icon/bedrock.webp"
import InstanceLogo_MinecraftArcLight from "@/assets/instance-icon/arclightlogo.jpg"
import InstanceLogo_MinecraftMohist from "@/assets/instance-icon/mohistLogo.webp"

//头像
import Avatar_1 from "@/assets/avatar/0510e794bed52c2b426a561cd3ffae0d.png";
import Avatar_2 from "@/assets/avatar/05a3df42355d4424a38ff1cdfbaf6e5c.png";
import Avatar_3 from "@/assets/avatar/0769e9d5dee3a6ba5746b637b7f9aca7.png";
import Avatar_4 from "@/assets/avatar/08b3147b82522594a93402f3ca1cd428_3166801321597279518.png";
import Avatar_5 from "@/assets/avatar/0a69843c2aa5b941f122605a8097e8b6.png";
import Avatar_6 from "@/assets/avatar/0dd43eb6da0e603cb5a1d208bd5f6c90_8154263631783171601.png";
import Avatar_7 from "@/assets/avatar/12c4469a2ab162e405b2030bcc68897f_5411829092175380376.png";
import Avatar_8 from "@/assets/avatar/1358947fa39f0e7fe02373b15bee4a98.png";
import Avatar_9 from "@/assets/avatar/191f5fa44e2815c538f1036330e4093d.png";
import Avatar_10 from "@/assets/avatar/222b847170feb3f2babcc1bd4f0e30dd.png";
import Avatar_11 from "@/assets/avatar/24ddf85abf93567268a6a77b5eaa34e8.png";
import Avatar_12 from "@/assets/avatar/261c1f924502b97fcdee16a63ec455cd.png";
import Avatar_13 from "@/assets/avatar/2872edd31066251e2430110fea06152a.png";
import Avatar_14 from "@/assets/avatar/29fab2f6cec22b79225726a73f1741c0.png";
import Avatar_15 from "@/assets/avatar/2adac6e4e0195c39d90696955f9a7902.png";
import Avatar_16 from "@/assets/avatar/2b88dc71beca9f3e0e4248e8456213f0.png";
import Avatar_17 from "@/assets/avatar/31ed69d405cd0b83662fffb7ad065b36_8018326635681353637.png";
import Avatar_18 from "@/assets/avatar/355f756ce3c1b8c7a0e887f93a2826c6.png";
import Avatar_19 from "@/assets/avatar/36f79d8af573250c70345d10d6fc5dd0.png";
import Avatar_20 from "@/assets/avatar/38a67cbf6a0bf5feadde8bde74025041.png";
import Avatar_21 from "@/assets/avatar/3f3333e65ddfa475fee74877bd410526.png";
import Avatar_22 from "@/assets/avatar/40dc988f4e89aea54dc412b6ed60a548.png";
import Avatar_23 from "@/assets/avatar/42ae758f3246a9bc209dd059fa6ae324.png";
import Avatar_24 from "@/assets/avatar/43c2bf44e066f3f763d0456100d6c2a6.png";
import Avatar_25 from "@/assets/avatar/4c1d9bbfdfcb964080600105ac6f37a9_3106213476075778205.png";
import Avatar_26 from "@/assets/avatar/4c46a63cf3805b2c588d9857402a8d31.png";
import Avatar_27 from "@/assets/avatar/4f2d713b5ac736e801962c959035a275.png";
import Avatar_28 from "@/assets/avatar/5044e0913f7458517022291a244cc879_1738988428545776680.png";
import Avatar_29 from "@/assets/avatar/52de23f1b1a060e4ccaa8b24c1305dd9.png";
import Avatar_30 from "@/assets/avatar/53154b35abc8ebff083cad65954a968d_5991584056277390592.png";
import Avatar_31 from "@/assets/avatar/53d2f20fbc21eb8f955ec5dd31018b53_5506571007262756908.png";
import Avatar_32 from "@/assets/avatar/59e62c79aefddf9d72a2a7e0af2a7e1f.png";
import Avatar_33 from "@/assets/avatar/5a022501d4e34aec571cad65d667b5db.png";
import Avatar_34 from "@/assets/avatar/5be773c6f876732c0b32e5401e4fc834.png";
import Avatar_35 from "@/assets/avatar/5f154d1fd6900eb80460cafdf9180118_6445056943313073599.png";
import Avatar_36 from "@/assets/avatar/5f673be35167d4cd5126978fdf36d9a6.png";
import Avatar_37 from "@/assets/avatar/6276a39fe6edc2cff18efd1e178970fd_5762932714640420254.png";
import Avatar_38 from "@/assets/avatar/633954f3bc184e5793b221fc46c423f4.png";
import Avatar_39 from "@/assets/avatar/66e1406f0a83b6bb77fe45070d4da42c.png";
import Avatar_40 from "@/assets/avatar/6961459d4637f5c23f166e12c4da6660.png";
import Avatar_41 from "@/assets/avatar/6ccdb0f1f61743895ac049702f74a507.png";
import Avatar_42 from "@/assets/avatar/7537c48f03523f00c8dcb0e3297aaa1a.png";
import Avatar_43 from "@/assets/avatar/776150fc42d323732b988b6a80150f34_6284538482909866672.png";
import Avatar_44 from "@/assets/avatar/77b466232303de73e16cadb5271d9ae2.png";
import Avatar_45 from "@/assets/avatar/798ca4a21e5cb4313b9d3b0ceec7b7d6.png";
import Avatar_46 from "@/assets/avatar/7a9d84a599c5dfc9db94ba27f5fcf6f1_8401284536415916117.png";
import Avatar_47 from "@/assets/avatar/7dcbd8aee1eb6ab0ea5fc4e27bc7c766_2919644670909786954.png";
import Avatar_48 from "@/assets/avatar/82402c79ed55769489d01a2a6cfff81d_5624469984532502763.png";
import Avatar_49 from "@/assets/avatar/833c23c4a705cf05dcc0aefde50181a2_8672820807120083624.png";
import Avatar_50 from "@/assets/avatar/8b8449abbfee0acd8fad3907675550f8.png";
import Avatar_51 from "@/assets/avatar/8c584252b605c3d8f84a3a2d155a66a3_9045338906631815985.png";
import Avatar_52 from "@/assets/avatar/8cb8be7ef963882482385aeee6c803df.png";
import Avatar_53 from "@/assets/avatar/8f10428e26cf765818b3985ee37e040c_5875984086405329184.png";
import Avatar_54 from "@/assets/avatar/91910857ccf82f41279b6e46753772db_8739184133236235227.png";
import Avatar_55 from "@/assets/avatar/923f95e7abe2074c740ba8701caabae2.png";
import Avatar_56 from "@/assets/avatar/92f9a25c45ed7de6056def887710e2be.png";
import Avatar_57 from "@/assets/avatar/931c31a1b5c4704a064f829cf9480266_1412295154723492382.png";
import Avatar_58 from "@/assets/avatar/96a14409d6f41a7532b0a4f99dff02d1.png";
import Avatar_59 from "@/assets/avatar/97734c89374997c7c87d5af5f7442171.png";
import Avatar_60 from "@/assets/avatar/9b3ba0955f25001047823e3ad904c87d.png";
import Avatar_61 from "@/assets/avatar/9c5c107bf7c217660c22fe13a1b71fe6.png";
import Avatar_62 from "@/assets/avatar/9c7ca480aab8665e16405da31a6f828b.png";
import Avatar_63 from "@/assets/avatar/9da76677b8d690e5be3fbbe01cbe12bf.png";
import Avatar_64 from "@/assets/avatar/9e14ea0d178f5eef9dc6274cc1d5d715.png";
import Avatar_65 from "@/assets/avatar/9e874c33ee69809f1c46dd448d2b86ee.png";
import Avatar_66 from "@/assets/avatar/a57113d5e6173a05f7980c978c5a2bd6.png";
import Avatar_67 from "@/assets/avatar/a8dffd89a6558efaf726c1fae960f4ec.png";
import Avatar_68 from "@/assets/avatar/a9648524739dcbed8deaedd1f1a2a78f.png";
import Avatar_69 from "@/assets/avatar/ab5ebe41f1b04d2aa7500fbfbeebcf10.png";
import Avatar_70 from "@/assets/avatar/ac024f9aeb9c69fba82e02d861b9ca15.png";
import Avatar_71 from "@/assets/avatar/avatar40001.png";
import Avatar_72 from "@/assets/avatar/avatar40004.png";
import Avatar_73 from "@/assets/avatar/avatar40005.png";
import Avatar_74 from "@/assets/avatar/avatar40017.png";
import Avatar_75 from "@/assets/avatar/avatar40025.png";
import Avatar_76 from "@/assets/avatar/avatar40030.png";
import Avatar_77 from "@/assets/avatar/b1493c45ae9c4c47877e5e8297046f90.png";
import Avatar_78 from "@/assets/avatar/bc0bd4bbc6da6ab930f64652868a033f.png";
import Avatar_79 from "@/assets/avatar/bc90fb382df3a3602484147311fdec21_2590969796959930140.png";
import Avatar_80 from "@/assets/avatar/c5b96563ce936179a7b31508a0031e83_5408188195159422039.png";
import Avatar_81 from "@/assets/avatar/c9947a19d1f9f1a8ad95d478957f6438.png";
import Avatar_82 from "@/assets/avatar/ca351a77e2541be8a5705ff20e115861.png";
import Avatar_83 from "@/assets/avatar/ca38485367778c82961deea9b4fcfe8a.png";
import Avatar_84 from "@/assets/avatar/cdbd8f33c917b27844f73623d423afe9.png";
import Avatar_85 from "@/assets/avatar/ce727afdf54b4c35b9533dd933e6b2ae.png";
import Avatar_86 from "@/assets/avatar/ce930523bb3ea2133aee2af1578d3646_7356180689517534313.png";
import Avatar_87 from "@/assets/avatar/d03fc42ba6b54e3570293358e128b35b_2191357708305592430.png";
import Avatar_88 from "@/assets/avatar/d51a7361bbf35342b43c7801d254d016.png";
import Avatar_89 from "@/assets/avatar/d90eea2754a51839ac8d895f5110a963.png";
import Avatar_90 from "@/assets/avatar/e0830ba5a338b6fd126afcaff044a887_2959533590319327575.png";
import Avatar_91 from "@/assets/avatar/e6bce92ab26a9890f3e4a2108ef7fae2.png";
import Avatar_92 from "@/assets/avatar/e84c8493bf9cd6b102eece6ec5ebd82e_2931682514717279175.png";
import Avatar_93 from "@/assets/avatar/e87259fa93bf9575777bcfdd1276fa32_8494250461442468419.png";
import Avatar_94 from "@/assets/avatar/e910bdba0b8c2123c405a710f5d75387.png";
import Avatar_95 from "@/assets/avatar/ec4e226f47a169749d96433dd63f391e.png";
import Avatar_96 from "@/assets/avatar/ed8f2ab21c2ae56989ac0482eb533a99.png";
import Avatar_97 from "@/assets/avatar/f55c356e78f8ba8dbc507613648edd2a_3143084876656215541.png";
import Avatar_98 from "@/assets/avatar/f5e86466be78e3aa5b4de9302b3894f0.png";
import Avatar_99 from "@/assets/avatar/faf2377e5a8c53f9957c80b709e115f2_8330120511938261415.png";
import Avatar_100 from "@/assets/avatar/fdfdf4068de504e448b53592cb0653a1.png";
import Avatar_101 from "@/assets/avatar/fea87bd29255f2bf68cdf2a777025cdc.png";


const store = createStore({
    state() {
        return {
            DEBUG: false,
            // DEBUG: true,
            globalUserInfo:{
                userId: 0,
                nickname: 'null',
                useCustomAvatar: false,
                innerAvatarId: 1,
                avatarFilename: '',
                avatar: require('@/assets/avatar/default_handsome.jpg'),
            },
            isAdminRole: false,
            formLabelPosition: 'left',
            UserRole: [
                {
                    label: '注册用户',
                    alias: 'role_user',
                    roleId: 0,
                },
                {
                    label: '管理员',
                    alias: 'role_admin',
                    roleId: 1,
                },
            ],
            IdVerifyTypes: [
                {
                    label: '身份证 | 中国大陆',
                    value: 0,
                    available: true
                },
                {
                    label: '身份证 | 港澳台(暂未支持)',
                    value: 1,
                    available: false
                },
                {
                    label: '护照(暂未支持)',
                    value: 2,
                    available: false
                },
            ],
            TopUpTypes: [
                {
                    label: '支付宝(Alipay)',
                    value: 1,
                    available: true,
                    logo: Alipay_Logo,
                },
                {
                    label: '微信(WeChatPay)(敬请期待)',
                    value: 2,
                    available: false,
                    logo: WeChat_Logo,
                },
                {
                    label: '云闪付(敬请期待)',
                    value: 3,
                    available: false,
                    logo: YunShanFu_Logo,
                },
            ],
            TopUpStatus: [
                {
                    label: '待支付',
                    value: 0,
                    tag_type: "warning",
                    alias: 'top_up_status_wait_to_pay',
                },
                {
                    label: '已完成',
                    value: 1,
                    tag_type: "success",
                    alias: 'top_up_status_ok',
                },
                {
                    label: '支付失败',
                    value: 2,
                    tag_type: "danger",
                    alias: 'top_up_status_failure',
                },
            ],
            VpsOsType: [
                {
                    label: "Windows"
                },
                {
                    label: "CentOS"
                },
                {
                    label: "Redhat"
                },
                {
                    label: "Ubuntu"
                },
                {
                    label: "Deepin"
                },
                {
                    label: "Debian"
                },
                {
                    label: "Fedora"
                },
                {
                    label: "UOS"
                },
            ],
            PveOsType: [
                {
                    label: "win11"
                },
                {
                    label: "win10"
                },
                {
                    label: "win8"
                },
                {
                    label: "win7"
                },
                {
                    label: "win2k8"
                },
                {
                    label: "wxp"
                },
                {
                    label: "win2k"
                },
                {
                    label: "l24"
                },
                {
                    label: "l26"
                },
            ],
            SourcePoolNetMode: [
                {
                    label: "NAT",
                    value: 0
                },
                {
                    label: "IP",
                    value: 1
                },
            ],
            VpsDiscountRoleStatus: [
                {
                    label: "未开始",
                    value: 0,
                    tag_type: 'info'
                },
                {
                    label: "生效中",
                    value: 1,
                    tag_type: 'success'
                },
                {
                    label: "已失效",
                    value: 2,
                    tag_type: 'warning'
                },
            ],
            OsLogo: [
                {
                    label: "Windows",
                    logo: OSLogo_Windows
                },
                {
                    label: "CentOS",
                    logo: OSLogo_CentOS
                },
                {
                    label: "Redhat",
                    logo: OSLogo_Redhat
                },
                {
                    label: "Ubuntu",
                    logo: OSLogo_Ubuntu
                },
                {
                    label: "Deepin",
                    logo: OSLogo_Deepin
                },
                {
                    label: "Debian",
                    logo: OSLogo_Debain
                },
                {
                    label: "Fedora",
                    logo: OSLogo_Fedora
                },
                {
                    label: "UOS",
                    logo: OSLogo_UOS
                },
                {
                    label: "Default",
                    logo: OSLogo_Default
                },
            ],
            OrderTypes: [
                {
                    label: '错误',
                    value: -1,
                    tag_type: 'danger',
                },
                {
                    label: '新购',
                    value: 0,
                    tag_type: 'success'
                },
                {
                    label: '续费',
                    value: 1,
                    tag_type: 'primary'
                },
                {
                    label: '升级',
                    value: 2,
                    tag_type: 'warning'
                },
                {
                    label: '加数据盘',
                    value: 3,
                    tag_type: 'success'
                },
            ],
            OrderStatus: [
                {
                    label: '错误',
                    value: -1,
                    tag_type: 'danger',
                },
                {
                    label: '待支付',
                    value: 0,
                    tag_type: 'warning',
                },
                {
                    label: '正在开通',
                    value: 1,
                    tag_type: 'warning',
                },
                {
                    label: '已完成',
                    value: 2,
                    tag_type: 'success',
                },
                {
                    label: '超时关闭',
                    value: 3,
                    tag_type: 'danger',
                },
                {
                    label: '开通异常',
                    value: 4,
                    tag_type: 'danger',
                },
                {
                    label: '已取消',
                    value: 5,
                    tag_type: 'info',
                },
            ],
            CouponServiceTypes: [
                {
                    label: '不限制',
                    value: 0,
                },
                {
                    label: 'VPS',
                    value: 1,
                },
                {
                    label: '云面板',
                    value: 2,
                },
                {
                    label: '虚拟主机',
                    value: 3,
                },
            ],
            CouponDiscountTypes: [
                {
                    label: '抵扣金额',
                    value: 0,
                },
                {
                    label: '比例优惠',
                    value: 1,
                },
            ],
            CouponLimitTypes: [
                {
                    label: '无限制',
                    value: 0,
                },
                {
                    label: '限制规格',
                    value: 1,
                },
                {
                    label: '限制分组',
                    value: 2,
                },
            ],
            CouponSourceTypes: [
                {
                    label: '系统发放',
                    value: 0,
                },
                {
                    label: '积分兑换',
                    value: 1,
                },
            ],
            DurationDays: [
                {
                    label: "一个月",
                    value: 1,
                    days: 30
                },
                {
                    label: "三个月",
                    value: 2,
                    days: 90
                },
                {
                    label: "半年",
                    value: 3,
                    days: 180
                },
                {
                    label: "一年",
                    value: 4,
                    days: 360
                },
            ],
            ProxmoxNodeStatus: [
                {
                    label: "未知",
                    value: -1,
                    tag_type: "info"
                },
                {
                    label: "正常",
                    value: 0,
                    tag_type: "success"
                },
                {
                    label: "维护",
                    value: 1,
                    tag_type: "warning"
                },
                {
                    label: "错误",
                    value: 2,
                    tag_type: "danger"
                },
            ],
            IkuaiRouterStatus: [
                {
                    label: "未知",
                    value: -1,
                    tag_type: "info"
                },
                {
                    label: "正常",
                    value: 0,
                    tag_type: "success"
                },
                {
                    label: "维护",
                    value: 1,
                    tag_type: "warning"
                },
                {
                    label: "错误",
                    value: 2,
                    tag_type: "danger"
                },
            ],
            VpsStatus: [
                {
                    label: "正常",
                    value: 1,
                    tag_type: ""
                },
                {
                    label: "部署中",
                    value: 0,
                    tag_type: "warning"
                },
                {
                    label: "维护中",
                    value: -2,
                    tag_type: "warning"
                },
                {
                    label: "异常",
                    value: -1,
                    tag_type: "danger"
                },
                {
                    label: "重装中",
                    value: 2,
                    tag_type: "warning"
                },
                {
                    label: "已过期",
                    value: 3,
                    tag_type: "info"
                },
                {
                    label: "回档中",
                    value: 5,
                    tag_type: "warning"
                },
                {
                    label: "已销毁",
                    value: 4,
                    tag_type: "info"
                },
            ],
            VpsPowerStatus: {
                PowerOn: {
                    label: "运行",
                    tag_type: "success"
                },
                PowerOff: {
                    label: "关机",
                    tag_type: "info"
                },
            },
            NetModes: [
                {
                    label: 'NAT',
                    value: 0
                },
                {
                    label: 'IP',
                    value: 1
                },
            ],
            NetMapProtocols: [
                {
                    label: 'TCP',
                    value: 'tcp'
                },
                {
                    label: 'UDP',
                    value: 'udp'
                },
                {
                    label: 'TCP+UDP',
                    value: 'tcp+udp'
                },
            ],
            MCSMInstanceTypes: [
                {
                    name: "Universal",
                    label: "通用（默认）",
                    value: 0,
                    logo: InstanceLogo_Universal
                },
                {
                    name: "UniversalMCDR",
                    label: "MCDR",
                    value: 1,
                    logo: InstanceLogo_Universal
                },
                {
                    name: "UniversalWebShell",
                    label: "WebShell",
                    value: 2,
                    logo: InstanceLogo_Universal
                },
                {
                    name: "SteamUniversal",
                    label: "SteamCMD",
                    value: 3,
                    logo: InstanceLogo_Steam
                },
                {
                    name: "MinecraftJava",
                    label: "MinecraftJava通用",
                    value: 4,
                    logo: InstanceLogo_Minecraft
                },
                {
                    name: "MinecraftJavaSpigot",
                    label: "Spigot服务端",
                    value: 5,
                    logo: InstanceLogo_MinecraftJavaSpigot
                },
                {
                    name: "MinecraftJavaPaper",
                    label: "Paper服务端",
                    value: 6,
                    logo: InstanceLogo_MinecraftJavaPaper
                },
                {
                    name: "MinecraftJavaBungeecord",
                    label: "Bungeecord群组服务端",
                    value: 7,
                    logo: InstanceLogo_MinecraftJava
                },
                {
                    name: "MinecraftJavaSponge",
                    label: "Sponge服务端",
                    value: 8,
                    logo: InstanceLogo_MinecraftJavaSponge
                },
                {
                    name: "MinecraftJavaForge",
                    label: "Forge服务端",
                    value: 9,
                    logo: InstanceLogo_MinecraftJavaForge
                },
                {
                    name: "MinecraftJavaFabric",
                    label: "Fabric服务端",
                    value: 10,
                    logo: InstanceLogo_MinecraftJavaFabric
                },
                {
                    name: "MinecraftJavaBukkit",
                    label: "Bukkit服务端",
                    value: 11,
                    logo: InstanceLogo_MinecraftJavaBukkit
                },
                {
                    name: "MinecraftJavaGeyser",
                    label: "Geyser服务端",
                    value: 12,
                    logo: InstanceLogo_MinecraftJavaGeyser
                },
                {
                    name: "MinecraftBedrock",
                    label: "Minecraft基岩服务端通用",
                    value: 13,
                    logo: InstanceLogo_MinecraftBedrock
                },
                {
                    name: "MinecraftBedrockBDS",
                    label: "BedrockBDS",
                    value: 14,
                    logo: InstanceLogo_MinecraftBedrock
                },
                {
                    name: "Terraria",
                    label: "Terraria",
                    value: 15,
                    logo: InstanceLogo_Terraria
                },
                {
                    name: "MinecraftMohist",
                    label: "Mohist服务端",
                    value: 16,
                    logo: InstanceLogo_MinecraftMohist
                },
                {
                    name: "MinecraftArcLight",
                    label: "ArcLight服务端",
                    value: 17,
                    logo: InstanceLogo_MinecraftArcLight
                },
            ],
            MCSMAppTypes: [
                {
                    label: 'Shell通用',
                    value: 1
                },
                {
                    label: 'SteamApp',
                    value: 2
                },
                {
                    label: 'Minecraft',
                    value: 3
                },
                {
                    label: 'Terraria',
                    value: 4
                },
            ],
            MCSMInstanceStatus: [
                {
                    label: '维护中',
                    value: -2,
                    tag_type: 'warning'
                },
                {
                    label: '异常',
                    value: -1,
                    tag_type: 'error'
                },
                {
                    label: '部署中',
                    value: 0,
                    tag_type: 'warning'
                },
                {
                    label: '正常',
                    value: 1,
                    tag_type: 'success'
                },
                {
                    label: '重装中',
                    value: 2,
                    tag_type: 'warning'
                },
                {
                    label: '已过期',
                    value: 3,
                    tag_type: 'warning'
                },
                {
                    label: '销毁',
                    value: 4,
                    tag_type: 'info'
                },
            ],
            MCSMRunningStatus:{
                Running: {
                    label: "运行中",
                    tag_type: "success"
                },
                Stopped: {
                    label: "已停止",
                    tag_type: "info"
                },
            },
            Avatars: [
                {
                    id: 1,
                    image: Avatar_1
                },
                {
                    id: 2,
                    image: Avatar_2
                },
                {
                    id: 3,
                    image: Avatar_3
                },
                {
                    id: 4,
                    image: Avatar_4
                },
                {
                    id: 5,
                    image: Avatar_5
                },
                {
                    id: 6,
                    image: Avatar_6
                },
                {
                    id: 7,
                    image: Avatar_7
                },
                {
                    id: 8,
                    image: Avatar_8
                },
                {
                    id: 9,
                    image: Avatar_9
                },
                {
                    id: 10,
                    image: Avatar_10
                },
                {
                    id: 11,
                    image: Avatar_11
                },
                {
                    id: 12,
                    image: Avatar_12
                },
                {
                    id: 13,
                    image: Avatar_13
                },
                {
                    id: 14,
                    image: Avatar_14
                },
                {
                    id: 15,
                    image: Avatar_15
                },
                {
                    id: 16,
                    image: Avatar_16
                },
                {
                    id: 17,
                    image: Avatar_17
                },
                {
                    id: 18,
                    image: Avatar_18
                },
                {
                    id: 19,
                    image: Avatar_19
                },
                {
                    id: 20,
                    image: Avatar_20
                },
                {
                    id: 21,
                    image: Avatar_21
                },
                {
                    id: 22,
                    image: Avatar_22
                },
                {
                    id: 23,
                    image: Avatar_23
                },
                {
                    id: 24,
                    image: Avatar_24
                },
                {
                    id: 25,
                    image: Avatar_25
                },
                {
                    id: 26,
                    image: Avatar_26
                },
                {
                    id: 27,
                    image: Avatar_27
                },
                {
                    id: 28,
                    image: Avatar_28
                },
                {
                    id: 29,
                    image: Avatar_29
                },
                {
                    id: 30,
                    image: Avatar_30
                },
                {
                    id: 31,
                    image: Avatar_31
                },
                {
                    id: 32,
                    image: Avatar_32
                },
                {
                    id: 33,
                    image: Avatar_33
                },
                {
                    id: 34,
                    image: Avatar_34
                },
                {
                    id: 35,
                    image: Avatar_35
                },
                {
                    id: 36,
                    image: Avatar_36
                },
                {
                    id: 37,
                    image: Avatar_37
                },
                {
                    id: 38,
                    image: Avatar_38
                },
                {
                    id: 39,
                    image: Avatar_39
                },
                {
                    id: 40,
                    image: Avatar_40
                },
                {
                    id: 41,
                    image: Avatar_41
                },
                {
                    id: 42,
                    image: Avatar_42
                },
                {
                    id: 43,
                    image: Avatar_43
                },
                {
                    id: 44,
                    image: Avatar_44
                },
                {
                    id: 45,
                    image: Avatar_45
                },
                {
                    id: 46,
                    image: Avatar_46
                },
                {
                    id: 47,
                    image: Avatar_47
                },
                {
                    id: 48,
                    image: Avatar_48
                },
                {
                    id: 49,
                    image: Avatar_49
                },
                {
                    id: 50,
                    image: Avatar_50
                },
                {
                    id: 51,
                    image: Avatar_51
                },
                {
                    id: 52,
                    image: Avatar_52
                },
                {
                    id: 53,
                    image: Avatar_53
                },
                {
                    id: 54,
                    image: Avatar_54
                },
                {
                    id: 55,
                    image: Avatar_55
                },
                {
                    id: 56,
                    image: Avatar_56
                },
                {
                    id: 57,
                    image: Avatar_57
                },
                {
                    id: 58,
                    image: Avatar_58
                },
                {
                    id: 59,
                    image: Avatar_59
                },
                {
                    id: 60,
                    image: Avatar_60
                },
                {
                    id: 61,
                    image: Avatar_61
                },
                {
                    id: 62,
                    image: Avatar_62
                },
                {
                    id: 63,
                    image: Avatar_63
                },
                {
                    id: 64,
                    image: Avatar_64
                },
                {
                    id: 65,
                    image: Avatar_65
                },
                {
                    id: 66,
                    image: Avatar_66
                },
                {
                    id: 67,
                    image: Avatar_67
                },
                {
                    id: 68,
                    image: Avatar_68
                },
                {
                    id: 69,
                    image: Avatar_69
                },
                {
                    id: 70,
                    image: Avatar_70
                },
                {
                    id: 71,
                    image: Avatar_71
                },
                {
                    id: 72,
                    image: Avatar_72
                },
                {
                    id: 73,
                    image: Avatar_73
                },
                {
                    id: 74,
                    image: Avatar_74
                },
                {
                    id: 75,
                    image: Avatar_75
                },
                {
                    id: 76,
                    image: Avatar_76
                },
                {
                    id: 77,
                    image: Avatar_77
                },
                {
                    id: 78,
                    image: Avatar_78
                },
                {
                    id: 79,
                    image: Avatar_79
                },
                {
                    id: 80,
                    image: Avatar_80
                },
                {
                    id: 81,
                    image: Avatar_81
                },
                {
                    id: 82,
                    image: Avatar_82
                },
                {
                    id: 83,
                    image: Avatar_83
                },
                {
                    id: 84,
                    image: Avatar_84
                },
                {
                    id: 85,
                    image: Avatar_85
                },
                {
                    id: 86,
                    image: Avatar_86
                },
                {
                    id: 87,
                    image: Avatar_87
                },
                {
                    id: 88,
                    image: Avatar_88
                },
                {
                    id: 89,
                    image: Avatar_89
                },
                {
                    id: 90,
                    image: Avatar_90
                },
                {
                    id: 91,
                    image: Avatar_91
                },
                {
                    id: 92,
                    image: Avatar_92
                },
                {
                    id: 93,
                    image: Avatar_93
                },
                {
                    id: 94,
                    image: Avatar_94
                },
                {
                    id: 95,
                    image: Avatar_95
                },
                {
                    id: 96,
                    image: Avatar_96
                },
                {
                    id: 97,
                    image: Avatar_97
                },
                {
                    id: 98,
                    image: Avatar_98
                },
                {
                    id: 99,
                    image: Avatar_99
                },
                {
                    id: 100,
                    image: Avatar_100
                },
                {
                    id: 101,
                    image: Avatar_101
                },
            ],
            AvatarMaxSize: 1048576, //1M
            AvatarAllowSuffixList: [
                '.jpg',
                '.jpeg',
                '.png',
                '.webp',
                '.svg'
            ],
            AnnouncementTypes: [
                {
                    label: '信息',
                    value: 0,
                },
                {
                    label: '重要通知',
                    value: 1,
                },
                {
                    label: '紧急通知',
                    value: 2,
                },
            ],
            SystemLogRoles: [
                {
                    label: '管理员',
                    value: 0,
                    tag_type: 'success'
                },
                {
                    label: '用户',
                    value: 1,
                    tag_type: 'primary'
                },
            ],
            SystemLogLevels: [
                {
                    label: '信息',
                    value: 1,
                    tag_type: 'info'
                },
                {
                    label: '告警',
                    value: 2,
                    tag_type: 'warning'
                },
                {
                    label: '错误',
                    value: 3,
                    tag_type: 'danger'
                },
            ],
            SystemLogServiceTypes: [
                {
                    label: '',
                    value: 0,
                },
                {
                    label: 'VPS',
                    value: 1,
                },
                {
                    label: '云面板',
                    value: 2,
                },
                {
                    label: '虚拟主机',
                    value: 3,
                },
            ],
            SystemTaskRoles: [
                {
                    label: '管理员',
                    value: 0,
                    tag_type: 'success'
                },
                {
                    label: '用户',
                    value: 1,
                    tag_type: 'primary'
                },
            ],
            SystemTaskStatus: [
                {
                    label: '运行中',
                    value: 1,
                    tag_type: 'primary'
                },
                {
                    label: '已完成',
                    value: 2,
                    tag_type: 'info'
                }
            ],
            SystemTaskOptions: [
                //VPS
                {
                    label: '开通部署',
                    value: 701,
                    tag_type: 'success'
                },
                {
                    label: '续费',
                    value: 702,
                    tag_type: 'info'
                },
                {
                    label: '配置升级',
                    value: 703,
                    tag_type: 'primary'
                },
                {
                    label: '重装系统',
                    value: 704,
                    tag_type: 'warning'
                },
                {
                    label: '启动电源',
                    value: 101,
                    tag_type: 'success'
                },
                {
                    label: '软关机',
                    value: 102,
                    tag_type: 'primary'
                },
                {
                    label: '硬重置',
                    value: 103,
                    tag_type: 'warning'
                },
                {
                    label: '强制停止',
                    value: 104,
                    tag_type: 'error'
                },
                {
                    label: '销毁VPS',
                    value: 901,
                    tag_type: 'error'
                },
                // MCSM
                {
                    label: '开通部署',
                    value: 801,
                    tag_type: 'success'
                },
                {
                    label: '续费',
                    value: 802,
                    tag_type: 'info'
                },
                {
                    label: '配置升级',
                    value: 803,
                    tag_type: 'primary'
                },
                {
                    label: '重装系统',
                    value: 804,
                    tag_type: 'warning'
                },
                {
                    label: '启动',
                    value: 201,
                    tag_type: 'success'
                },
                {
                    label: '停止',
                    value: 202,
                    tag_type: 'primary'
                },
                {
                    label: '重启',
                    value: 203,
                    tag_type: 'warning'
                },
                {
                    label: '强制停止',
                    value: 204,
                    tag_type: 'error'
                },
                {
                    label: '销毁MCSM',
                    value: 301,
                    tag_type: 'error'
                },
            ],
            SystemTaskResults: [
                {
                    label: '失败',
                    value: -1,
                    tag_type: 'error'
                },
                {
                    label: '-',
                    value: 0,
                    tag_type: 'info'
                },
                {
                    label: '成功',
                    value: 1,
                    tag_type: 'success'
                },
            ],
            SystemTaskLogLevels: [
                {
                    label: 'DEBUG',
                    value: 0,
                    text_type: 'info'
                },
                {
                    label: 'INFO',
                    value: 1,
                    text_type: 'success'
                },
                {
                    label: 'WARN',
                    value: 2,
                    text_type: 'warning'
                },
                {
                    label: 'ERROR',
                    value: 3,
                    text_type: 'error'
                },
            ],
            VpsManageOptions: [
                {
                    label: '批量操作',
                    value: 0,
                },
                {
                    label: '开机',
                    value: 1,
                },
                {
                    label: '关机',
                    value: 2,
                },
                {
                    label: '重启',
                    value: 3,
                },
                {
                    label: '重置',
                    value: 4,
                },
                {
                    label: '停止',
                    value: 6,
                },
                {
                    label: '销毁',
                    value: 9,
                },
            ],
            McsmManageOptions: [
                {
                    label: '请选择',
                    value: 0,
                },
                {
                    label: '启动',
                    value: 1,
                },
                {
                    label: '停止',
                    value: 2,
                },
                {
                    label: '重启',
                    value: 3,
                },
                {
                    label: '停止',
                    value: 6,
                },
                {
                    label: '销毁',
                    value: 9,
                },
            ],
        }
    },
    getters: {
        getUserAvatarSrc: (state) => () =>{
            if(state.globalUserInfo.useCustomAvatar === true){
                //如果使用自定义头像
                state.globalUserInfo.avatar = "/userAvatar/" + state.globalUserInfo.userId + "/" + state.globalUserInfo.avatarFilename;
                // state.globalUserInfo.avatar = "/api" + "/userAvatar/" + state.globalUserInfo.userId + "/" + state.globalUserInfo.avatarFilename;
            }else{
                state.globalUserInfo.avatar = state.Avatars[0].image;
                state.Avatars.forEach(item => {
                    if (item.id == state.globalUserInfo.innerAvatarId) {
                        state.globalUserInfo.avatar = item.image;
                    }
                });
            }
        },
        getUserRoleByRoleId: (state) => (roleId) => {
            let role = state.UserRole[0];
            state.UserRole.forEach(roleItem => {
                if (roleItem.roleId === roleId) {
                    role = roleItem;
                }
            });
            return role;
        },
        getIdVerifyTypeByValue: (state) => (value) => {
            let verifyType = state.IdVerifyTypes[0];
            state.IdVerifyTypes.forEach(item => {
                if (item.value === value) {
                    verifyType = item;
                }
            });
            return verifyType;
        },
        getTopUpTypeByValue: (state) => (value) => {
            let topUpType = state.TopUpTypes[0];
            state.TopUpTypes.forEach(item => {
                if (item.value === value) {
                    topUpType = item;
                }
            });
            return topUpType;
        },
        getTopUpStatusByValue: (state) => (value) => {
            let topUpStatus = state.TopUpStatus[0];
            state.TopUpStatus.forEach(item => {
                if (item.value === value) {
                    topUpStatus = item;
                }
            });
            return topUpStatus;
        },
        getVpsDiscountRoleStatusByValue: (state) => (value) => {
            let status = state.VpsDiscountRoleStatus[0];
            state.VpsDiscountRoleStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            });
            return status;
        },
        getOsLogoByLabel: (state) => (label) => {
            let logo = OSLogo_Default;
            state.OsLogo.forEach(item => {
                if (item.label === label) {
                    logo = item.logo;
                }
            });
            return logo;
        },
        getOrderTypeByValue: (state) => (value) => {
            let type = state.OrderTypes[0];
            state.OrderTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getOrderStatusByValue: (state) => (value) => {
            let status = state.OrderStatus[0];
            state.OrderStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            });
            return status;
        },
        getCouponServiceTypeByValue: (state) => (value) => {
            let type = state.CouponServiceTypes[0];
            state.CouponServiceTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getCouponDiscountTypeByValue: (state) => (value) => {
            let type = state.CouponDiscountTypes[0];
            state.CouponDiscountTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getDurationDayByValue: (state) => (value) => {
            let type = state.DurationDays[0];
            state.DurationDays.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getCouponLimitTypeByValue: (state) => (value) => {
            let type = state.CouponLimitTypes[0];
            state.CouponLimitTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getCouponSourceTypeByValue: (state) => (value) =>{
            let source = state.CouponSourceTypes[0];
            state.CouponSourceTypes.forEach(item => {
                if (item.value === value){
                    source = item;
                }
            });
            return source;
        },
        getProxmoxNodeStatusByValue: (state) => (value) => {
            let status = state.ProxmoxNodeStatus[0];
            state.ProxmoxNodeStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            })
            return status;
        },
        getIkuaiRouterStatusByValue: (state) => (value) => {
            let status = state.IkuaiRouterStatus[0];
            state.IkuaiRouterStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            })
            return status;
        },
        getVpsStatusByValue: (state) => (value) => {
            let status = state.VpsStatus[0];
            state.VpsStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            })
            return status;
        },
        getVpsPowerStatus: (state) => (value) => {
            return value ? state.VpsPowerStatus.PowerOn : state.VpsPowerStatus.PowerOff;
        },
        getNetModeByValue: (state) => (value) => {
            let netmode = state.NetModes[0];
            state.NetModes.forEach(item => {
                if (item.value === value) {
                    netmode = item;
                }
            });
            return netmode;
        },
        getNetMapProtocolByValue: (state) => (value) => {
            let protocol = state.NetMapProtocols[0];
            state.NetMapProtocols.forEach(item => {
                if (item.value === value) {
                    protocol = item;
                }
            });
            return protocol;
        },
        getMcsmInstanceTypeByValue: (state) => (value) => {
            let result = state.MCSMInstanceTypes[0];
            state.MCSMInstanceTypes.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getMcsmAppTypeByValue: (state) => (value) => {
            let result = state.MCSMAppTypes[0];
            state.MCSMAppTypes.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getMcsmInstanceStatusByValue:(state) => (value) => {
            let result = state.MCSMInstanceStatus[0];
            state.MCSMInstanceStatus.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getMcsmRunningStatus: (state) => (value) => {
          return value ? state.MCSMRunningStatus.Running : state.MCSMRunningStatus.Stopped;
        },
        getAnnouncementTypeByValue: (state) => (value) => {
            let type = state.AnnouncementTypes[0];
            state.AnnouncementTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getSystemLogRoleByValue: (state) => (value) => {
            let role = state.SystemLogRoles[0];
            state.SystemLogRoles.forEach(item => {
                if (item.value === value) {
                    role = item;
                }
            });
            return role;
        },
        getSystemLogLevelByValue: (state) => (value) => {
            let role = state.SystemLogLevels[0];
            state.SystemLogLevels.forEach(item => {
                if (item.value === value) {
                    role = item;
                }
            });
            return role;
        },
        getSystemLogServiceTypeByValue: (state) => (value) => {
            let type = state.SystemLogServiceTypes[0];
            state.SystemLogServiceTypes.forEach(item => {
                if (item.value === value) {
                    type = item;
                }
            });
            return type;
        },
        getSystemTaskLevelByValue: (state) => (value) => {
            let role = state.SystemTaskRoles[0];
            state.SystemTaskRoles.forEach(item => {
                if (item.value === value) {
                    role = item;
                }
            });
            return role;
        },
        getSystemTaskStatusByValue: (state) => (value) => {
            let status = state.SystemTaskStatus[0];
            state.SystemTaskStatus.forEach(item => {
                if (item.value === value) {
                    status = item;
                }
            });
            return status;
        },
        getSystemTaskOptionsByValue: (state) => (value) => {
            let option = state.SystemTaskOptions[0];
            state.SystemTaskOptions.forEach(item => {
                if (item.value === value) {
                    option = item;
                }
            });
            return option;
        },
        getSystemTaskResultByValue: (state) => (value) => {
            let result = state.SystemTaskResults[0];
            state.SystemTaskResults.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getSystemTaskLogLevelByValue: (state) => (value) => {
            let result = state.SystemTaskLogLevels[0];
            state.SystemTaskLogLevels.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getVpsOptionByValue: (state) => (value) => {
            let result = state.VpsManageOptions[0];
            state.VpsManageOptions.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
        getMcsmOptionByValue: (state) => (value) => {
            let result = state.McsmManageOptions[0];
            state.McsmManageOptions.forEach(item => {
                if (item.value === value) {
                    result = item;
                }
            });
            return result;
        },
    },
    mutations: {}
});

export default store;